<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">

          <a-col :md="8" :sm="24">
            <a-form-item label="模块标题">
              <a-input v-model="queryParam.title" placeholder="请输入模块标题" />
            </a-form-item>
          </a-col>
          <!--（0其它 1新增 2修改 3删除）-->
          <a-col :md="8" :sm="24" :style="{ display: expand ? 'block' : 'none' }">
            <a-form-item label="方法类型">
              <a-select v-model="queryParam.businessType" placeholder="请选择方法类型">
                <a-select-option v-for="item in methodList" :key="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="8" :sm="24" :style="{ display: expand ? 'block' : 'none' }">
            <a-form-item label="请求方式">
              <a-input v-model="queryParam.requestMethod" placeholder="请输入请求方式" />
            </a-form-item>
          </a-col>

          <!--（0其它 1后台用户 2手机端用户）-->
          <a-col :md="8" :sm="24" :style="{ display: expand ? 'block' : 'none' }">
            <a-form-item label="操作类别">
              <a-select v-model="queryParam.operatorType" placeholder="请选择操作类别">
                <a-select-option v-for="item in actionTypeList" :key="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="8" :sm="24" :style="{ display: expand ? 'block' : 'none' }">
            <a-form-item label="操作人员">
              <a-input v-model="queryParam.operName" placeholder="请输入操作人员" />
            </a-form-item>
          </a-col>

          <a-col :md="8" :sm="24" :style="{ display: expand ? 'block' : 'none' }">
            <a-form-item label="请求URL">
              <a-input v-model="queryParam.requestUrl" placeholder="请输入请求URL" />
            </a-form-item>
          </a-col>

          <a-col :md="8" :sm="24">
            <a-form-item label="操作状态">
              <a-select
                v-model="queryParam.status"
                placeholder="请选择操作状态"
              >
                <a-select-option :value="0">
                  正常
                </a-select-option>
                <a-select-option :value="1">
                  异常
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="8" :sm="24" :style="{ display: expand ? 'block' : 'none' }">
            <a-form-item label="操作时间范围">
              <a-config-provider :locale="zhCN">
                <a-range-picker
                  :show-time="{ format: 'HH:mm:ss' }"
                  :placeholder="['开始时间', '结束时间']"
                  v-model="timeStamps"
                  format="YYYY-MM-DD HH:mm:ss"
                  style="width:100%"
                >
                </a-range-picker>
              </a-config-provider>
            </a-form-item>
          </a-col>

          <a-col :md="!advanced && 8 || 24" :sm="24">
            <span
              class="table-page-search-submitButtons"
              :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
              <a-button type="primary" @click="selectList">查询</a-button>
              <a-button style="margin-left: 8px" @click="() => {queryParam = {};timeStamps = []}">重置</a-button>
              <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
                更多 <a-icon :type="expand ? 'up' : 'down'" />
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <s-table
      ref="table"
      size="default"
      :columns="columns"
      :data="loadData"
      :rowKey="(record) => record.id"
    >
      <span slot="operatorType" slot-scope="operatorType">
        <span v-if="operatorType==0">
          其它
        </span>
        <span v-else-if="1">
          后台用户
        </span>
        <span v-else>
          手机端用户
        </span>
      </span>
      <span slot="status" slot-scope="status" class="status-info">
        <div v-if="status===0">
          <span class="top" />
          <span>
            正常
          </span>
        </div>
        <div v-else>
          <span class="out" />
          <span>
            异常
          </span>
        </div>
      </span>
      <span slot="timeStamp" slot-scope="timeStamp">
        {{ moment(timeStamp).format('YYYY-MM-DD HH:mm:ss') }}
      </span>
      <span slot="businessType" slot-scope="businessType" class="status-info">
        <span v-if="businessType===0">
          其它
        </span>
        <span v-else-if="businessType===1">
          新增
        </span>
        <span v-else-if="businessType===2">
          修改
        </span>
        <span v-else>
          删除
        </span>
        <!--        <span slot="action" slot-scope="text, record">-->
        <!--          <a @click="$refs.editForm.edit(record)">详情</a>-->
        <!--        </span>-->
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click="$refs.editForm.edit(record)">详情</a>
      </span>
    </s-table>
    <edit-form ref="editForm" @ok="handleOk" />
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { page } from '@/api/system/operlog'
import editForm from './acOperLogEditForm'
import zhCN from 'ant-design-vue/es/locale/zh_CN'

export default {
  components: {
    STable,
    editForm
  },

  data () {
    return {
      zhCN: zhCN,
      advanced: false, // 高级搜索 展开/关闭
      queryParam: {
        // 当前页数
        pageNum: 1,
        //每页条数
        pageSize: 10
      }, // 查询参数
      columns: [ // 表头
        {
          title: '编号',
          dataIndex: 'id'
        },
        {
          title: '模块标题',
          dataIndex: 'title'
        },
        // （0其它 1新增 2修改 3删除）
        {
          title: '方法类型',
          dataIndex: 'businessType',
          scopedSlots: { customRender: 'businessType' }
        },
        // {
        //   title: '方法名称',
        //   dataIndex: 'method'
        // },
        {
          title: '请求方式',
          dataIndex: 'requestMethod'
        },
        // （0其它 1后台用户 2手机端用户）
        {
          title: '操作类别',
          dataIndex: 'operatorType',
          scopedSlots: { customRender: 'operatorType' }
        },
        {
          title: '操作人员',
          dataIndex: 'operName'
        },
        {
          title: '请求URL',
          dataIndex: 'requestUrl'
        },
        // {
        //   title: '请求参数',
        //   dataIndex: 'requestParam',
        //   scopedSlots: { customRender: 'requestParam' }
        // },
        // {
        //   title: '返回参数',
        //   dataIndex: 'responeResult'
        // },
        {
          title: '操作状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作时间',
          dataIndex: 'timeStamp',
          scopedSlots: { customRender: 'timeStamp' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        // 分页 pageNum 当前页 pageSize当前页大小
        this.queryParam = Object.assign(this.queryParam,{pageNum: parameter.pageNo,pageSize: parameter.pageSize})
        return page(this.queryParam).then((res) => {
          if (res.code === 200) {
            res.data = res['rows']
            res.totalCount = res['total']
            res.pageNo = this.queryParam.pageNum
            return res
          } else {
            res.data = []
            res.totalCount = res.pageNo = 0
            return res
          }
        })
      },
      // 显示隐藏
      expand: false,
      // 方法类型
      methodList: [{ value: 0, label: '其它' }, { value: 1, label: '新增' }, { value: 2, label: '修改' }, {
        value: 3,
        label: '删除'
      }],
      // 操作类别
      actionTypeList: [{ value: 0, label: '其它' }, { value: 1, label: '后台用户' }, { value: 2, label: '手机端用户' }],
      // 时间类型
      timeStamps: []
    }
  },

  methods: {
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    handleOk () {
      this.$refs.table.refresh()
    },
    /**
     * 取反
     */
    toggle () {
      this.expand = !this.expand
    },
    /**
     * 列表查询
     */
    selectList () {
      let beginTime
      let endTime
      if (this.timeStamps.length) {
        beginTime = this.moment(this.timeStamps[0]).format('YYYY-MM-DD HH:mm:ss')
        endTime = this.moment(this.timeStamps[1]).format('YYYY-MM-DD HH:mm:ss')
      }
      this.queryParam = Object.assign(this.queryParam, {}, { beginTime, endTime })
      this.$refs.table.refresh(true)
    }
  }
}
</script>

<style lang="less">

.table-operator {
  margin-bottom: 18px;
}

button {
  margin-right: 8px;
}

.status-info {
  position: relative;
}

.top {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: rgb(22, 144, 255);
  position: absolute;
  top: 9px;
  left: -11px
}

.out {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: rgb(243, 34, 45);
  position: absolute;
  top: 9px;
  left: -11px
}
</style>
