import { axios } from '@/utils/request'

/**
 * 分页查询操作日志记录
 *
 * @author taidi
 * @date 2021/02/23 11:15
 */
export function page (parameter) {
    return axios({
        url: '/log/listOperLog',
        method: 'get',
        params: parameter
    })
}
